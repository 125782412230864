import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Loader from "../../components/spinner/Loader";
import DismissableAlert from "../../components/DismissableAlert";
import TransactionDetail from "../../components/TransactionDetail";
import axios from "axios";
import { Link } from "react-router-dom";
import { Base } from "../../components/Base";

function Wallet(props) {
  const [name, setName] = useState("");
  const [balance, setBalance] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [RecentTransactions, setRecentTransactions] = useState(false);

  function myGreeting() {
    let greeting;

    let time = new Date().getHours();
    if (time < 10) {
      greeting = "Good morning";
    } else if (time < 20) {
      greeting = "Good day";
    } else {
      greeting = "Good evening";
    }
    return greeting;
  }
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const loggedInUser = sessionStorage.getItem("user");
      let foundUser;
      if (loggedInUser) {
        foundUser = JSON.parse(loggedInUser);
        const TOKEN = foundUser.data.token;
        try {
          const response = await axios.get(
            `${Base}/user/profile/view`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${TOKEN}`,
              },
            }
          );
          const wallet = await axios.get(
            `${Base}/user-wallet/balance`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${TOKEN}`,
              },
            }
          );

          const transactions = await axios.get(
            `${Base}/user-transaction-logs`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${TOKEN}`,
              },
            }
          );

          if (
            wallet.data.status &&
            response.status === 200 &&
            transactions.status
          ) {
            setLoading(false);
            setBalance(wallet.data.data.balance);
            setName(response.data.data);
            setRecentTransactions(transactions.data.data);
          } else {
            setRecentTransactions("");
          }
        } catch (err) {
          setLoading(false);
          setErrorMessage(err.message);
          if (err.message === "Request failed with status code 401") {
            sessionStorage.removeItem("user");
            props.history.push("/auth/login");
          }
        }
      } else {
        props.history.push("/auth/login");
      }
    }
    fetchData();
  }, [props.history]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <Row className="m-auto">
      {!loading ? (
        <Container className="Wallet">
          {errorMessage && (
            <DismissableAlert
              handleClose={() => setErrorMessage("")}
              message={errorMessage}
            />
          )}
          {name && (
            <Container className="mt-4">
              <h3>{name.firstname},</h3>
              <span>{myGreeting()}</span>
              <Row className="Sections">
                <Col xs={11} md={3} lg={3}>
                  <h4>Balance</h4>
                  <h4>NGN {balance && balance}</h4>
                  <Link to="/dashboard/fund" className="Fund">
                    <h4>
                      Fund{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <svg
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          fillRule="evenodd"
                          clipRule="evenodd"
                        >
                          <path
                            id="card"
                            d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm5.247 8l-5.247 6.44-5.263-6.44-.737.678 6 7.322 6-7.335-.753-.665z"
                          />
                        </svg>
                      </svg>
                    </h4>
                  </Link>
                </Col>
                <Col xs={11} md={3} lg={3}>
                  <h4>Total Transactions</h4>
                  <h4>
                    NGN{" "}
                    {RecentTransactions &&
                      RecentTransactions.reduce((acc, transaction) => {
                        let { amount } = transaction;

                        let value = acc + parseFloat(amount);
                        return value;
                      }, 0)}
                    .00
                  </h4>
                </Col>
                <Col xs={11} md={3} lg={3}>
                  <h4>Transaction Count</h4>
                  <h4>{RecentTransactions && RecentTransactions.length}</h4>
                </Col>
              </Row>

              <div className="RecentTxn">
                <h3>Recent Transactions</h3>

                {RecentTransactions && (
                  <div>
                    {RecentTransactions.slice(0, 3).map((transaction) => (
                      <TransactionDetail
                        type={transaction?.transaction_type}
                        amount={numberWithCommas(transaction.amount)}
                        date={transaction?.transaction_date}
                        key={transaction?.transaction_ref}
                        reference={transaction?.transaction_ref}
                        status={transaction?.status}
                        name={transaction?.transaction_name}
                      />
                    ))}
                  </div>
                )}
                {Object.keys(RecentTransactions).length === 0 ? (
                  <p
                    style={{
                      margin: "10px auto",
                      textAlign: "center",
                      fontWeight: "200",
                      fontSize: "13px",
                    }}
                  >
                    No recent transaction
                  </p>
                ) : (
                  <div
                    style={{
                      margin: "5px auto",
                    }}
                  >
                    <Link
                      onClick={() => props.history.push("/dashboard/summary")}
                      to="/dashboard/summary"
                    >
                      View more
                    </Link>
                  </div>
                )}
              </div>
            </Container>
          )}
        </Container>
      ) : (
        <Loader />
      )}
    </Row>
  );
}

export default Wallet;
