import React, { useContext, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { AppContext } from "../../components/AppContext";

function TopBar(props) {
  const [user, setUser] = useContext(AppContext);
  let foundUser;
  useEffect(() => {
    const fetchUser = async () => {
      const loggedInUser = sessionStorage.getItem("user");
      console.log(loggedInUser);
      if (loggedInUser) {
        foundUser = JSON.parse(loggedInUser);
        setUser(foundUser);
      }
    };
    fetchUser();
  }, [props.history, setUser]);

  return (
    <div>
    {user ? (

      <Nav
        activeKey={props.history.location.pathname}
        className="asideNav dash d-none d-md-block d-lg-block nav"
      >
        <Nav.Link href="/dashboard">Wallet</Nav.Link>
        <Nav.Link href="/dashboard/buyairtime">Buy Airtime</Nav.Link>
        <Nav.Link href="/dashboard/buydata">Buy Data</Nav.Link>
        <Nav.Link href="/dashboard/cabletv">Cable TV</Nav.Link>
        <Nav.Link href="/dashboard/electicity-bill">Electricity</Nav.Link>
        {/* <Nav.Link href="/dashboard/internet">Internet</Nav.Link> */}
        <Nav.Link href="/dashboard/summary">Account Summary</Nav.Link>
        <Nav.Link href="/dashboard/settings">Settings</Nav.Link>
      </Nav>
    ): null }
    </div>
  );
}

export default TopBar;
