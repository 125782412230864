import React, { useState, createRef, useEffect } from "react";
import { Form, Spinner, Button, Alert } from "react-bootstrap";
import dstv from "../../assets/billers/dstv.jpg";
import gotv from "../../assets/billers/gotv.jpg";
import startimes from "../../assets/billers/startimes.jpg";
import axios from "axios";
import Loader from "../../components/spinner/Loader";
import DismissableAlert from "../../components/DismissableAlert";
import SuccessAlert from "../../components/SuccessAlert";
import { Base } from "../../components/Base";
import Skeleton from "react-loading-skeleton";
import toast, { Toaster } from "react-hot-toast";

function CableTV(props) {
  const [returnedValues, setReturnedValue] = useState("");
  const [returnedError, setReturnedError] = useState("");
  const [fetcher, setFetcher] = useState(false);
  const [startimesPlans, setStartimesPlans] = useState("");
  const [dstvPlans, setdstvPlans] = useState("");
  const [gotvPlans, setgotvPlans] = useState("");
  const [balance, setBalance] = useState("");

  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [TOKEN, setTOKEN] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [clicked, setClicked] = useState({
    dstv: false,
    gotv: false,
    startimes: false,
  });
  let token;
  useEffect(() => {
    setLoading(true);
    setReturnedValue("");
    setReturnedError("");
    setFetcher(false);
    const fetchData = async () => {
      const loggedInUser = await sessionStorage.getItem("user");
      //console.log(loggedInUser);
      let foundUser;
      if (loggedInUser) {
        foundUser = await JSON.parse(loggedInUser);
        token = foundUser.data.token;
        setTOKEN(foundUser.data.token);
        try {
          const responseDSTV = await axios.get(
            `${Base}/cable-subscription/get-plans/30`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setdstvPlans(responseDSTV.data.data);

          const responseGOTV = await axios.get(
            `${Base}/cable-subscription/get-plans/40`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setgotvPlans(responseGOTV.data.data);

          const responseSTARTIMES = await axios.get(
            `${Base}/cable-subscription/get-plans/70`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setStartimesPlans(responseSTARTIMES.data.data);

          const wallet = await axios.get(
            `${Base}/user-wallet/balance`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setBalance(wallet.data.data.balance);

          if (
            responseDSTV.data.status &&
            responseGOTV.data.status &&
            responseSTARTIMES.data.status &&
            wallet.data.status
          ) {
            setLoading(false);
            setClicked({ dstv: true });
          }
        } catch (err) {
          setLoading(false);
          setErrorMessage(err.message);
          if (err.message === "Request failed with status code 401") {
            sessionStorage.removeItem("user");
            props.history.push("/auth/login");
          }
        }
      } else {
        props.history.push("/auth/login");
      }
      //console.log(user);
    };
    fetchData();
  }, []);

  const phoneRef = createRef();
  const iucRef = createRef();
  const selectRef = createRef();

  const clickedClassdstv = clicked.dstv ? "Selected" : "";
  const clickedClassgotv = clicked.gotv ? "Selected" : "";
  const clickedClassstartimes = clicked.startimes ? "Selected" : "";

  const ID = clicked.dstv ? 30 : clicked.gotv ? 40 : clicked.startimes ? 70 : 0;
  const handleChange = async (e) => {
    const data = {
      cable_number: iucRef.current.value,
    };
    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    if (e.target.value.length >= 10) {
      setFetcher(true);
      if (clicked.dstv) {
        setFetcher(true);
        try {
          setFetcher(true);
          const dstvResponse = await axios.post(
            `${Base}/cable-subscription/verify/${ID}`,
            data,
            { headers }
          );
          console.log(dstvResponse.data);
          if (dstvResponse.data.status) {
            setFetcher(false);
            setReturnedValue(dstvResponse.data);
            setReturnedError("");
          } else {
            setReturnedValue("");
            setFetcher(false);
            setReturnedError(dstvResponse.data);
          }
        } catch (err) {
          console.log(err);
        }
      } else if (clicked.gotv) {
        try {
          setFetcher(true);
          const gotvResponse = await axios.post(
            `${Base}/cable-subscription/verify/${ID}`,
            data,
            { headers }
          );
          console.log(gotvResponse.data);
          if (gotvResponse.data.status) {
            setFetcher(false);
            setReturnedValue(gotvResponse.data);
            setReturnedError("");
          } else {
            setFetcher(false);
            setReturnedError(gotvResponse.data);
          }
        } catch (err) {
          console.log(err);
        }
      } else if (clicked.startimes) {
        try {
          setFetcher(true);
          const startimesResponse = await axios.post(
            `${Base}/cable-subscription/verify/${ID}`,
            data,
            { headers }
          );
          console.log(startimesResponse.data);
          if (startimesResponse.data.status) {
            setFetcher(false);
            setReturnedValue(startimesResponse.data);
            setReturnedError("");
          } else {
            setReturnedValue("");
            setFetcher(false);
            setReturnedError(startimesResponse.data);
          }
        } catch (err) {
          console.log(err);
          if (err.message === "Request failed with status code 401") {
            sessionStorage.removeItem("user");
            props.history.push("/auth/login");
          }
        }
      }
    }
  };

  const handleClick = (name) => {
    switch (name) {
      case "dstv":
        setClicked({ dstv: true });
        break;
      case "gotv":
        setClicked({ gotv: true });
        break;
      case "startimes":
        setClicked({ startimes: true });
        break;
      default:
        setClicked("");
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setValidated(true);
    const data = {
      cable_number: iucRef.current.value,
      customer_name: returnedValues?.data?.name,
      customer_number: phoneRef.current.value,
      amount: selectRef.current.value,
    };

    // console.log(data);
    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    try {
      const response = await axios.post(
        `${Base}/cable-subscription/buy/${ID}`,
        data,
        { headers }
      );
      
      if (response.data.status) {
         toast.success(response?.data?.message);
        const wallet = await axios.get(
          `${Base}/user-wallet/balance`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${TOKEN}`,
            },
          }
        );
        setBalance(wallet.data.data.balance);
        setSuccessMessage(response.data.message);
        setValidated(false);
        setReturnedValue("");
        e.target.reset();
      } else {
        toast.error(response?.data?.message);
        setErrorMessage(
          response.data.message
        );
        setValidated(false);
      }
    } catch (err) {
      setValidated(false);
      setErrorMessage(err.message);
      console.log(err);
      toast.error(err.message);
      if (err.message === "Request failed with status code 401") {
        sessionStorage.removeItem("user");
        props.history.push("/auth/login");
      }
    }
  }

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="Purchase">
          <h1 className="text-center">Pay for TV Subscription</h1>
          <Form onSubmit={handleSubmit}>
            
            <Form.Label>Select provider</Form.Label>
            <div className="BillClass">
              <span
                onClick={() => handleClick("dstv")}
                className={`Bills ${clickedClassdstv}`}
              >
                <img src={dstv} alt="dstv" />
              </span>
              <span
                onClick={() => handleClick("gotv")}
                className={`Bills ${clickedClassgotv}`}
              >
                <img src={gotv} alt="gotv" />
              </span>
              <span
                onClick={() => handleClick("startimes")}
                className={`Bills ${clickedClassstartimes}`}
              >
                <img src={startimes} alt="startimes" />
              </span>
            </div>
            <Form.Group
              controlId="exampleForm.SelectCustom"
              style={{ textAlign: "start" }}
            >
              <Form.Label>Subscription plan</Form.Label>
              <div style={{ width: "100%" }}></div>
              <Form.Control size="lg" as="select" ref={selectRef}>
                {clicked.dstv &&
                  dstvPlans.map((data) => {
                    return (
                      <option value={`${data.amount}`} key={data.code}>
                        N{data.amount} - {data.name}
                      </option>
                    );
                  })}
                {clicked.gotv &&
                  gotvPlans.map((data) => {
                    return (
                      <option value={`${data.amount}`} key={data.code}>
                        N{data.amount} - {data.name}
                      </option>
                    );
                  })}
                {clicked.startimes &&
                  startimesPlans.map((data) => {
                    return (
                      <option
                        value={`${data.amount}`}
                        key={data.channels + data.amount}
                      >
                        N{data.amount} - {data.name}
                      </option>
                    );
                  })}
              </Form.Control>
              <div
                style={{
                  textAlign: "start",
                  color: "blue",
                  margin: "10px 0",
                }}
              >
                Balance: NGN {balance && balance}
              </div>
            </Form.Group>
            <Form.Group style={{ textAlign: "start" }}>
              <Form.Label>Phone number</Form.Label>
              <Form.Control
                size="lg"
                ref={phoneRef}
                type="text"
                maxLength={11}
                inputMode="tel"
                pattern="\d*"
                placeholder="Phone number"
                required
              />
            </Form.Group>
            <Form.Group style={{ textAlign: "start" }}>
              <Form.Label>IUC Number</Form.Label>
              <Form.Control
                size="lg"
                ref={iucRef}
                type="text"
                maxLength={11}
                onChange={handleChange}
                placeholder="Enter IUC number"
                required
              />
            </Form.Group>
            {fetcher && <Skeleton />}
            {returnedValues.status ? (
              <Alert variant="success">{returnedValues.data.name}</Alert>
            ) : returnedError.status === false ? (
              <Alert variant="danger">verification failed</Alert>
            ) : null}

            <Button
              type="submit"
              size="md"
              block
              style={{ backgroundColor: "#4267b2" }}
              disabled={returnedValues && !validated ? false : true}
            >
              {validated ? "Loading...." : "Pay subscription now"}
            </Button>
          </Form>
        </div>
      )}
    </div>
  );
}

export default CableTV;
