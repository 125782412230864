import React from "react";
import { SmileyIcon } from "../components/UsableSvg";

export default function Close() {
  return (
    <div style={styles.container}>
      <p style={styles.text}>Please close this modal</p>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: 35,
    letterSpacing: 0.5,
    fontWeight: "400",
  },
};
