import React from "react";
import { Container } from "react-bootstrap";

function Copyright() {
  return (
    <Container className="text-center">
      {"Copyright © "}
      MASTERPRIME {new Date().getFullYear()} - RC 1441533
      {"."}
    </Container>
  );
}

export default Copyright;
