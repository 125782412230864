import React, { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import axios from "axios";
import DismissableAlert from "../components/DismissableAlert";
import SuccessAlert from "../components/SuccessAlert";
import { Base } from "../components/Base";


function Recovery(props) {
  const email = React.createRef();
  const [status, setStatus] = useState({
    loading: false,
    errorMessage: "",
    successMessage: "",
  });

  const handleSubmit = async (e) => {
    setStatus({ loading: true });
    e.preventDefault();
    try {
      const data = {
        email: email.current.value,
      };

      const response = await axios.post(
        `${Base}/user/forgot-password/password-reset-code`,
        data,
        {
          Accept: "application/json",
        }
      );
      if (response.data.status === true) {
        setStatus({ successMessage: response.data.message });
        props.history.push("/password_reset");
      } else {
        setStatus({ errorMessage: response.data.message });
      }
    } catch (err) {
      console.log(err);
      setStatus({ errorMessage: err.message });
    }
  };

  return (
    <div>
      <Container style={{ marginTop: "200px" }} className="text-center">
        {status.errorMessage && (
          <DismissableAlert
            handleClose={() => setStatus({ errorMessage: "" })}
            variant={true}
            message={status.errorMessage}
          />
        )}
        {status.successMessage && (
          <SuccessAlert
            handleClose={() => setStatus({ successMessage: "" })}
            variant={true}
            message={status.successMessage}
          />
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Enter your email</Form.Label>
            <Form.Control
              ref={email}
              style={{
                width: "250px",
                margin: "10px auto",
              }}
              type="email"
              required
            />
          </Form.Group>

          <Button
            style={{
              width: "250px",
              margin: "10px auto",
            }}
            type="submit"
            block
            disabled={status.loading ? true : false}
          >
            {status.loading ? "Loading" : "Submit"}
          </Button>
        </Form>
      </Container>
    </div>
  );
}

export default Recovery;
