import React from 'react'

function Error() {
    return (
        <div style={styles.container}>
            <h1 style={styles.text}>OOPS there is nothing here</h1>
        </div>
    )
}
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: 35,
    letterSpacing: 0.5,
    fontWeight: "400",
  },
};
export default Error;
