import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";
import AsideImg from "../components/AsideImg";
import { PersonCircle } from "react-bootstrap-icons";
import axios from "axios";
import { AppContext } from "../components/AppContext";
import DismissableAlert from "../components/DismissableAlert";
import { Link } from "react-router-dom";
import { Base } from "../components/Base";


export default function Login(props) {
  const emailRef = React.createRef();
  const passwordRef = React.createRef();
  const [user, setUser] = useContext(AppContext);
  const [status, setStatus] = useState({
    loading: false,
    errorMessage: "",
  });

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ loading: true });
    try {
      const data = {
        email: emailRef.current.value,
        password: passwordRef.current.value,
      };

      const response = await axios.post(
        `${Base}/user/login`,
        data,
        {
          Accept: "application/json",
        }
      );
      if (response.data.status === false) {
        setStatus({ errorMessage: response.data.message });
      } else if (response.data.status === true) {
        setUser(response.data);
        e.target.reset();
        sessionStorage.setItem("user", JSON.stringify(response.data));
        props.history.push("/dashboard");
      }
    } catch (error) {
      setStatus({ loading: false });
      setStatus({ errorMessage: error.message });
    }
  };

  return (
    <div>
      <Container fluid>
        <div style={{ marginBottom: "50px" }}></div>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit}>
              <Form.Group
                style={{
                  textAlign: "center",
                }}
              >
                <PersonCircle color="royalblue" size={66} />
              </Form.Group>

              <Form.Group
                style={{
                  textAlign: "center",
                  fontSize: "27px",
                  fontWeight: "bold",
                }}
              >
                <Form.Text>Please login</Form.Text>
              </Form.Group>
              {status.errorMessage ? (
                <DismissableAlert
                  handleClose={() => setStatus({ errorMessage: "" })}
                  message={
                    status.errorMessage === "Account has not been activated" ? (
                      <Alert.Link href="/auth/activate/resend">
                        {status.errorMessage} Click here to activate
                      </Alert.Link>
                    ) : (
                      status.errorMessage
                    )
                  }
                />
              ) : null}
              <Form.Group>
                <Form.Control
                  name="email"
                  type="email"
                  size="lg"
                  inputMode="email"
                  placeholder="Enter email"
                  ref={emailRef}
                  required
                />
              </Form.Group>

              <Form.Group className="passControl">
                <Form.Control
                  name="password"
                  type="password"
                  size="lg"
                  placeholder="Password"
                  ref={passwordRef}
                  required
                />
              </Form.Group>
              <div></div>
              <Form.Group className="ml-1" as={Row}>
                <Form.Text
                  style={{
                    fontSize: "17px",
                    textDecoration: "none",
                  }}
                >
                  <a href="/password_recovery">Forgot password?</a>
                </Form.Text>
              </Form.Group>

              <Button
                size="md"
                variant="primary"
                type="submit"
                disabled={status.loading && true}
                block
                style={{ backgroundColor: "#4267b2" }}
              >
                {status.loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Login"
                )}
              </Button>
              <Col className="accountAlt">
                New to MasterPrime? <Link to="/auth/sign-up">Sign Up here</Link>
              </Col>
            </Form>
            <Row></Row>
          </Col>
          <Col className="d-none d-md-block d-lg-block">
            <AsideImg />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
