import React, { useState, createRef, useEffect } from "react";
import axios from "axios";
import { Form } from "react-bootstrap";
import mtn from "../../assets/billers/mtn.jpg";
import glo from "../../assets/billers/glo.jpg";
import airtel from "../../assets/billers/airtel.jpg";
import etisalat from "../../assets/billers/9mobile.jpg";
import { Button } from "react-bootstrap";
import Loader from "../../components/spinner/Loader";
import DismissableAlert from "../../components/DismissableAlert";
import SuccessAlert from "../../components/SuccessAlert";
import { Base } from "../../components/Base";
import toast, { Toaster } from "react-hot-toast";

function BuyData(props) {
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [balance, setBalance] = useState("");
  const [TOKEN, setTOKEN] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  
  useEffect(() => {
    setCompleted(false);
    setLoading(true);
    setValidated(false);
    setSuccessMessage("");
    setErrorMessage("");
    const fetchData = async () => {
      const loggedInUser = sessionStorage.getItem("user");
      //console.log(loggedInUser);
      let foundUser;
      if (loggedInUser) {
        foundUser = JSON.parse(loggedInUser);
        setTOKEN(foundUser.data.token);
        let token = foundUser.data.token;

        try {
          const responseMTN = await axios.get(
            `${Base}/data-bundle/plans/2`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setMtnData(responseMTN.data.data);

          const responseGLO = await axios.get(
            `${Base}/data-bundle/plans/3`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setGloData(responseGLO.data.data);

          const responseAIRTEL = await axios.get(
            `${Base}/data-bundle/plans/1`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setAirtelData(responseAIRTEL.data.data);

          const responseETISALAT = await axios.get(
            `${Base}/data-bundle/plans/4`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setEtisalatData(responseETISALAT.data.data);
          const wallet = await axios.get(
            `${Base}/user-wallet/balance`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setBalance(wallet.data.data.balance);
          if (
            responseMTN.data.status &&
            responseGLO.data.status &&
            responseAIRTEL.data.status &&
            responseETISALAT.data.status
          ) {
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
          setErrorMessage(err.message);
          if (err.message === "Request failed with status code 401") {
            sessionStorage.removeItem("user");
            props.history.push("/auth/login");
          }
        }
      } else {
        props.history.push("/auth/login");
      }
      //console.log(user);
    };
    fetchData();
  }, []);

  const [clicked, setClicked] = useState({
    mtn: false,
    glo: false,
    airtel: false,
    etisalat: false,
  });

  const [mtnData, setMtnData] = useState("");
  const [gloData, setGloData] = useState("");
  const [airtelData, setAirtelData] = useState("");
  const [etisalatData, setEtisalatData] = useState("");

  const phoneRef = createRef();
  const selectRef = createRef();

  const clickedClassMtn = clicked.mtn ? "Selected" : "";
  const clickedClassGlo = clicked.glo ? "Selected" : "";
  const clickedClassAirtel = clicked.airtel ? "Selected" : "";
  const clickedClassEtisalat = clicked.etisalat ? "Selected" : "";
  const handleChange = () => {
    let extract = phoneRef.current.value.substring(0, 4);
    if (phoneRef.current.value > 10) {
      setCompleted(true);
    } else {
      setCompleted(false);
    }
    switch (extract) {
      case "0816":
        setClicked({ mtn: true });
        break;
      case "0803":
        setClicked({ mtn: true });
        break;
      case "0806":
        setClicked({ mtn: true });
        break;
      case "0813":
        setClicked({ mtn: true });
        break;
      case "0814":
        setClicked({ mtn: true });
        break;
      case "0810":
        setClicked({ mtn: true });
        break;
      case "0703":
        setClicked({ mtn: true });
        break;
      case "0704":
        setClicked({ mtn: true });
        break;
      case "0706":
        setClicked({ mtn: true });
        break;
      case "0903":
        setClicked({ mtn: true });
        break;
      case "0913":
        setClicked({ mtn: true });
        break;
      case "0805":
        setClicked({ glo: true });
        break;
      case "0705":
        setClicked({ glo: true });
        break;
      case "0905":
        setClicked({ glo: true });
        break;
      case "0807":
        setClicked({ glo: true });
        break;
      case "0815":
        setClicked({ glo: true });
        break;
      case "0811":
        setClicked({ glo: true });
        break;
      case "0802":
        setClicked({ airtel: true });
        break;
      case "0902":
        setClicked({ airtel: true });
        break;
      case "0904":
        setClicked({ airtel: true });
        break;
      case "0907":
        setClicked({ airtel: true });
        break;
      case "0701":
        setClicked({ airtel: true });
        break;
      case "0808":
        setClicked({ airtel: true });
        break;
      case "0708":
        setClicked({ airtel: true });
        break;
      case "0812":
        setClicked({ airtel: true });
        break;
      case "0809":
        setClicked({ etisalat: true });
        break;
      case "0909":
        setClicked({ etisalat: true });
        break;
      case "0817":
        setClicked({ etisalat: true });
        break;
      case "0818":
        setClicked({ etisalat: true });
        break;
      default:
        setClicked("");
    }
  };
  const [modalShow, setModalShow] = React.useState(false);
  const ID = clicked.airtel
    ? 1
    : clicked.mtn
    ? 2
    : clicked.glo
    ? 3
    : clicked.etisalat
    ? 4
    : 0;
  async function handleSubmit(e) {
    e.preventDefault();
    setValidated(true);
   
    const data = {
      amount: selectRef.current.value,
      phone_number: phoneRef.current.value,
    };
   
    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    try {
      const response = await axios.post(
        `${Base}/data-bundle/buy/${ID}`,
        data,
        { headers }
      );
      // console.log(response.data);
      if (response.data.status) {
         toast.success(response?.data?.message);
        const wallet = await axios.get(
          `${Base}/user-wallet/balance`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${TOKEN}`,
            },
          }
        );
        setBalance(wallet.data.data.balance);
        setSuccessMessage(response.data.message);
        setValidated(false);
        e.target.reset();
      } else {
        toast.error(response?.data?.message);
        setErrorMessage(
          response.data.message
        );
        setValidated(false);
      }
    } catch (err) {
      setErrorMessage(err.message);
      setValidated(false);
    }
  }

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="Purchase">
          <Toaster
            position="bottom-center"
            toastOptions={{
              // Define default options
              className: "",
              duration: 5000,
              style: {
                background: "#363636",
                color: "#fff",
              },
              // Default options for specific types
              success: {
                duration: 3000,
                theme: {
                  primary: "green",
                  secondary: "black",
                },
              },
              error: {
                duration: 3000,
                theme: {
                  primary: "red",
                  secondary: "black",
                },
              },
            }}
          />
          <h1 className="text-center">Buy Data</h1>
          <Form onSubmit={handleSubmit}>
            
            <Form.Group style={{ textAlign: "start" }}>
              <Form.Label>Phone number</Form.Label>
              <Form.Control
                size="lg"
                ref={phoneRef}
                onChange={handleChange}
                type="text"
                maxLength={11}
                inputMode="tel"
                pattern="\d*"
                placeholder="Phone number"
                required
              />
            </Form.Group>
            <div className="BillersClass">
              <span className={`Billers ${clickedClassMtn}`}>
                <img src={mtn} alt="mtn" />
              </span>
              <span className={`Billers ${clickedClassGlo}`}>
                <img src={glo} alt="glo" />
              </span>
              <span className={`Billers ${clickedClassAirtel}`}>
                <img src={airtel} alt="airtel" />
              </span>
              <span className={`Billers ${clickedClassEtisalat}`}>
                <img src={etisalat} alt="9mobile" />
              </span>
            </div>

            <Form.Group
              controlId="exampleForm.SelectCustom"
              style={{ textAlign: "start" }}
            >
              <Form.Label>Subscription plan</Form.Label>
              <div style={{ width: "100%" }}></div>
              <Form.Control size="lg" as="select" ref={selectRef}>
                {clicked.mtn &&
                  mtnData.map((data) => {
                    return (
                      <option
                        value={data.amount}
                        key={data.code + data.validity + data.description}
                      >
                        N{data.amount} - {data.description} {data.validity}
                      </option>
                    );
                  })}
                {clicked.glo &&
                  gloData.map((data) => {
                    return (
                      <option
                        value={data.amount}
                        key={data.code + data.validity + data.description}
                      >
                        N{data.amount} - {data.description} {data.validity}
                      </option>
                    );
                  })}
                {clicked.airtel &&
                  airtelData.map((data) => {
                    return (
                      <option
                        value={data.amount}
                        key={data.code + data.validity + data.description}
                      >
                        N{data.amount} - {data.description} {data.validity}
                      </option>
                    );
                  })}
                {clicked.etisalat &&
                  etisalatData.map((data) => {
                    return (
                      <option
                        value={data.name}
                        key={data.code + data.validity + data.description}
                      >
                        N{data.amount} - {data.description} {data.validity}
                      </option>
                    );
                  })}
              </Form.Control>
            </Form.Group>

            <div
              style={{
                textAlign: "start",
                color: "blue",
                margin: "15px 0",
              }}
            >
              Balance: NGN {balance && balance}
            </div>
            <Button
              type="submit"
              size="md"
              block
              style={{ backgroundColor: "#4267b2" }}
              disabled={completed && !validated ? false : true}
            >
              {validated ? "Loading...." : "Buy data now"}
            </Button>
          </Form>
        </div>
      )}
    </div>
  );
}

export default BuyData;
