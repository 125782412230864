import React, { useState, createRef, useEffect } from "react";
import axios from "axios";
import { Row, Container, Form, Button, Spinner } from "react-bootstrap";
import { Gear } from "react-bootstrap-icons";
import DismissableAlert from "../../components/DismissableAlert";
import SuccessAlert from "../../components/SuccessAlert";
import { Base } from "../../components/Base";

function Settings(props) {
  let foundUser;
  let TOKEN;
  useEffect(() => {
    const fetchUser = async () => {
      const loggedInUser = sessionStorage.getItem("user");
      if (loggedInUser) {
        foundUser = await JSON.parse(loggedInUser);
        TOKEN = foundUser.data.token;
      } else {
        props.history.push("/auth/login");
      }
    };
    fetchUser();
  }, [props.history]);

  const [status, setStatus] = useState({
    loading: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const current_password = createRef();
  const new_password = createRef();
  const confirm_new_password = createRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let passwords = {
      current_password: current_password.current.value,
      new_password: new_password.current.value,
      confirm_new_password: confirm_new_password.current.value,
    };
    
    setStatus({ loading: true });
    try {
      const response = await axios.post(
        `${Base}/user/settings/change-password`,
        passwords,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${TOKEN}`,
          },
        }
      );
      if (response.data.status === true) {
        setSuccessMessage(response.data.message);
        setStatus({ loading: false });
        e.target.reset();
        console.log(response.data);
      } else {
        setErrorMessage(response.data.message);
        setStatus({ loading: false });
      }
    } catch (err) {
      console.log(err);
      if (err.message === "Request failed with status code 401") {
        sessionStorage.removeItem("user");
        props.history.push("/auth/login");
      }
    }
  };

  return (
    <div>
      <Container>
        <h2
          style={{
            backgroundColor: "#4267b2",
            padding: "10px",
            color: "#fff",
            fontWeight: "300",
            fontSize: "22px",
          }}
          className="text-center"
        >
          Settings <Gear size="20" />
        </h2>
        <Row>
          <Container>
            {successMessage && (
              <SuccessAlert
                handleClose={() => setSuccessMessage("")}
                message={successMessage}
              />
            )}
            {errorMessage && (
              <DismissableAlert
                handleClose={() => setErrorMessage("")}
                message={errorMessage}
              />
            )}
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Control
                  name="current_password"
                  type="password"
                  placeholder="current password"
                  ref={current_password}
                  size="lg"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  name="new_password"
                  type="password"
                  placeholder="new password"
                  ref={new_password}
                  size="lg"
                  required
                />
                <Form.Text id="passwordHelpBlock" muted>
                  Must be at least 8 characters long.
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  name="confirm_new_password"
                  type="password"
                  placeholder="confirm new password"
                  ref={confirm_new_password}
                  size="lg"
                  required
                />
              </Form.Group>

              <Button
                size="md"
                type="submit"
                disabled={status.loading && true}
                style={{ backgroundColor: "#4267b2" }}
                block
              >
                {status.loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Update"
                )}
              </Button>
            </Form>
          </Container>
        </Row>
      </Container>
    </div>
  );
}

export default Settings;
