import React, { createRef, useState } from "react";
import axios from "axios";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import DismissableAlert from "../components/DismissableAlert";
import SuccessAlert from "../components/SuccessAlert";
import {Base} from "../components/Base";

function ReActivate(props) {
  const emailRef = createRef();
  const [status, setStatus] = useState({
    loading: false,
    errorMessege: "",
    successMessage: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        email: emailRef.current.value,
      };

      const response = await axios.post(
        `${Base}/user/resend-activation-code`,
        data,
        { Accept: "application/json" }
      );
      if (response.data.status === false) {
        setStatus({ errorMessage: response.data.message });
      } else {
        e.target.reset();
        setStatus({ successMessage: response.data.message });
        await props.history.push("/auth/activate");
        console.log(response.data.data.activation_code);
      }
    } catch (err) {
      console.log(err);
      setStatus({ errorMessage: err.message });
    }
  };
  return (
    <div>
      <Container style={{marginTop: "200px"}} className="text-center">
        {/* temporary */}
        {status.errorMessage && (
          <DismissableAlert
            handleClose={() => setStatus({ errorMessage: "" })}
            variant={true}
            message={status.errorMessage}
          />
        )}
        {status.successMessage && (
          <SuccessAlert
            handleClose={() => setStatus({ successMessage: "" })}
            variant={true}
            message={status.successMessage}
          />
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Enter your email</Form.Label>
            <Form.Control style={{
                width: "150px",
                margin: "10px auto",
              }} type="email" required ref={emailRef}></Form.Control>
          </Form.Group>

          <Button style={{
                width: "150px",
                margin: "10px auto",
              }} type="submit" block>
            resend
          </Button>
        </Form>
      </Container>
    </div>
  );
}

export default ReActivate;
