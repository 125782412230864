import React, { useState, createContext } from "react";

export const AppContext = createContext();

export const AppProvider = (props) => {
  const [loading, setLoading] = useState({
    isLoading: false,
  });
  const [user, setUser] = useState("");
  return (
    <AppContext.Provider value={[user, setUser]}>
      {props.children}
    </AppContext.Provider>
  );
};
