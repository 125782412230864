import React, { useState, createRef, useEffect } from "react";
import { Form, InputGroup, Button, Toast } from "react-bootstrap";
import mtn from "../../assets/billers/mtn.jpg";
import glo from "../../assets/billers/glo.jpg";
import airtel from "../../assets/billers/airtel.jpg";
import etisalat from "../../assets/billers/9mobile.jpg";
import axios from "axios";
import Loader from "../../components/spinner/Loader";
import DismissableAlert from "../../components/DismissableAlert";
import SuccessAlert from "../../components/SuccessAlert";
import { Base } from "../../components/Base";
import toast, { Toaster } from "react-hot-toast";


function BuyAirtime(props) {
  let foundUser;
  const [balance, setBalance] = useState("");
  const [TOKEN, setTOKEN] = useState("");
  const [validated, setValidated] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  

  let token;
  useEffect(() => {
    
    setLoading(true);
    setCompleted(false);
    setClicked({ mtn: false });
    setClicked({ airtel: false });
    setClicked({ glo: false });
    setClicked({ etisalat: false });
    setValidated(false);
    setSuccessMessage(false);
    setErrorMessage(false);
    const fetchUser = async () => {
      const loggedInUser = sessionStorage.getItem("user");
      console.log(loggedInUser);
      if (loggedInUser) {
        foundUser = JSON.parse(loggedInUser);
        token = foundUser.data.token;
        setTOKEN(foundUser.data.token);
        try {
          const wallet = await axios.get(
            `${Base}/user-wallet/balance`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (wallet.data.status) {
            setBalance(wallet.data.data.balance);
            setLoading(false);
          }
        } catch (err) {
          setErrorMessage(err.message);
          setLoading(false);
          if (err.message === "Request failed with status code 401") {
            sessionStorage.removeItem("user");
            props.history.push("/auth/login");
          }
        }
      } else {
        props.history.push("/auth/login");
      }
    };
    fetchUser();
  }, [props.history]);
  const [clicked, setClicked] = useState({
    mtn: false,
    glo: false,
    airtel: false,
    etisalat: false,
  });
  
  const phoneRef = createRef();
  const amountRef = createRef();

  const clickedClassMtn = clicked.mtn ? "Selected" : "";
  const clickedClassGlo = clicked.glo ? "Selected" : "";
  const clickedClassAirtel = clicked.airtel ? "Selected" : "";
  const clickedClassEtisalat = clicked.etisalat ? "Selected" : "";
  const handleChange = () => {
    let extract = phoneRef.current.value.substring(0, 4);
    if (amountRef.current.value >= 10 && phoneRef.current.value.length > 10) {
      setCompleted(true);
    } else {
      setCompleted(false);
    }
    switch (extract) {
      case "0816":
        setClicked({ mtn: true });
        break;
      case "0803":
        setClicked({ mtn: true });
        break;
      case "0806":
        setClicked({ mtn: true });
        break;
      case "0813":
        setClicked({ mtn: true });
        break;
      case "0814":
        setClicked({ mtn: true });
        break;
      case "0810":
        setClicked({ mtn: true });
        break;
      case "0703":
        setClicked({ mtn: true });
        break;
      case "0706":
        setClicked({ mtn: true });
        break;
      case "0704":
        setClicked({ mtn: true });
        break;
      case "0903":
        setClicked({ mtn: true });
        break;
      case "0913":
        setClicked({ mtn: true });
        break;
      case "0805":
        setClicked({ glo: true });
        break;
      case "0705":
        setClicked({ glo: true });
        break;
      case "0905":
        setClicked({ glo: true });
        break;
      case "0807":
        setClicked({ glo: true });
        break;
      case "0815":
        setClicked({ glo: true });
        break;
      case "0811":
        setClicked({ glo: true });
        break;
      case "0802":
        setClicked({ airtel: true });
        break;
      case "0902":
        setClicked({ airtel: true });
        break;
      case "0907":
        setClicked({ airtel: true });
        break;
      case "0701":
        setClicked({ airtel: true });
        break;
      case "0808":
        setClicked({ airtel: true });
        break;
      case "0708":
        setClicked({ airtel: true });
        break;
      case "0812":
        setClicked({ airtel: true });
        break;
      case "0904":
        setClicked({ airtel: true });
        break;
      case "0809":
        setClicked({ etisalat: true });
        break;
      case "0909":
        setClicked({ etisalat: true });
        break;
      case "0817":
        setClicked({ etisalat: true });
        break;
      case "0818":
        setClicked({ etisalat: true });
        break;
      default:
        setClicked("");
    }
  };

;
 
  const ID = clicked.airtel
    ? 1
    : clicked.mtn
    ? 2
    : clicked.glo
    ? 3
    : clicked.etisalat
    ? 4
    : 0;

  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      phone_number: phoneRef.current.value,
      amount: amountRef.current.value,
    };
    setValidated(true);

    

    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    try {
      const response = await axios.post(
        `${Base}/airtime/buy/${ID}`,
        data,
        { headers }
      );
      // console.log(response.data);
      if (response.data.status) {
        toast.success(response?.data?.message);
        const wallet = await axios.get(
          `${Base}/user-wallet/balance`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${TOKEN}`,
            },
          }
        );
        e.target.reset();

        setValidated(false)
        if (wallet.data.status) {
          setBalance(wallet.data.data.balance);
          setSuccessMessage(response.data.message);
        }
      
      } else {
        toast.error(response?.data?.message);
        setErrorMessage(
          response.data.message
        );
        setValidated(false);
      } 
    } catch (err) {
      setValidated(false);
      setErrorMessage(err.message);
      toast.error(err.message);
      console.log(err);
      if (err.message === "Request failed with status code 401") {
        toast.error(err.message);
        sessionStorage.removeItem("user");
        props.history.push("/auth/login");
      }
    }
  }



  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="Purchase">
          <h1 className="text-center">Buy Airtime</h1>
          <Toaster
            position="bottom-center"
            toastOptions={{
              // Define default options
              className: "",
              duration: 5000,
              style: {
                background: "#363636",
                color: "#fff",
              },
              // Default options for specific types
              success: {
                duration: 3000,
                theme: {
                  primary: "green",
                  secondary: "black",
                },
              },
              error: {
                duration: 3000,
                theme: {
                  primary: "red",
                  secondary: "black",
                },
              },
            }}
          />
          <Form onSubmit={handleSubmit}>
            <Form.Group style={{ textAlign: "start" }}>
              <Form.Label>Phone number</Form.Label>
              <Form.Control
                size="lg"
                ref={phoneRef}
                onChange={handleChange}
                type="text"
                maxLength={11}
                inputMode="tel"
                pattern="\d*"
                placeholder="Phone number"
                required
              />
            </Form.Group>
            <div className="BillersClass">
              <span className={`Billers ${clickedClassMtn}`}>
                <img src={mtn} alt="mtn" />
              </span>
              <span className={`Billers ${clickedClassGlo}`}>
                <img src={glo} alt="glo" />
              </span>
              <span className={`Billers ${clickedClassAirtel}`}>
                <img src={airtel} alt="airtel" />
              </span>
              <span className={`Billers ${clickedClassEtisalat}`}>
                <img src={etisalat} alt="9mobile" />
              </span>
            </div>
            <InputGroup>
              <Form.Label>Amount you want to recharge</Form.Label>
              <div style={{ width: "100%" }}></div>
              <InputGroup.Prepend>
                <InputGroup.Text>NGN</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                size="lg"
                ref={amountRef}
                onChange={handleChange}
                placeholder="Amount (minimum is 50)"
                inputMode="decimal"
                required
              />
            </InputGroup>
            <div
              style={{
                textAlign: "start",
                color: "blue",
                margin: "15px 0",
              }}
            >
              Balance: NGN {balance && balance}
            </div>
            <Button
              type="submit"
              size="md"
              block
              style={{ backgroundColor: "#4267b2" }}
              disabled={completed && !validated ? false : true}
              
            >
              {validated ? "Loading...." : "Recharge now"}
            </Button>
          </Form>
        </div>
      )}
    </div>
  );
}

export default BuyAirtime;
