import React, { useState, useEffect } from "react";
import asideImg from "../assets/images/asideimg.png";



function AsideImg() {

  return (
    <img
      src={asideImg}
      alt="asideImage"
      width="100%"
      height="500px"
    />
  );
}
export default AsideImg;
