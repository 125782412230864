import React from "react";
import { Container } from "react-bootstrap";

function Terms() {
  return (
    <Container>
      <div id="Terms">
        <div>
          <h1>Terms And Conditions</h1>

          <p>
            Please read these terms and conditions carefully before using
            masterprime management services Limited website/app for the
            "service" we offer.
          </p>
        </div>
        <div id="conditions">
          <h3>Conditions of Use:</h3>
          <p>
            Our services to you are subjected to the conditions stated below in
            this document. Every time you visit our website or use our app, use
            its services or make a purchase, you accept the following
            conditions. This is why we urge you to read them carefully.
          </p>
        </div>
        <div id="modification">
          <h3>Modification of Terms and Conditions:</h3>
          <p>
            We reserve the right to modify any of the terms and conditions
            contained in this Agreement, at any time and at our sole discretion
            for any reason. Any changes will be posted on this site and a
            general notification made to all members. Continuing use of this
            /app will be your acceptance of the change to the Terms and
            Conditions.
          </p>
        </div>
        <div id="privacy">
          <h3>Privacy and Security Policy:</h3>
          <p>
            We take your privacy very seriously. In order to protect you. we
            hold only a minimal amount of data from you which are required to
            operate your account. Users may update and amend account details at
            any time. It is the responsibility of the user to keep account log
            in details secure at all times. We cannot be held responsible for
            any unlawful access to a user’s account due to the intentional or
            unintentional compromise of username password or other log in
            details.
          </p>
        </div>
        <div id="copyright">
          <h3>Copyright:</h3>
          <p>
            Content published on this website/app (digital downloads, images,
            texts, graphics, logos) is the property of Masterprime Management
            services Limited and/or its content creators and protected by
            copyright laws. The entire compilation of the content found on this
            website/mobile app is the exclusive property of Masterprime
            Management services limited, with copyright authorship for this
            compilation by Masterprime Management services Limited.
          </p>
        </div>
        <div id="unauthorised">
          <h3>Unauthorised Use:</h3>
          <p>
            Any unauthorised use of this site/app is forbidden. Any unauthorised
            use or suspected unauthorised use will be investigated and may
            result in your account being suspended or terminated. Termination of
            your account may cause the forfeiture of your wallet. Unauthorised
            use includes tampering of web pages/ app, modification of url's,
            hacking, spidering of web pages, running of automatic scripts or
            anything deemed to be abnormal use.
          </p>
        </div>
        <div>
          <h3 id="indemnification">Indemnification:</h3>
          <p>
            You agree to indemnify Masterprime Management services Limited
            against any loss, liability, claim, demand, damage or expense
            (including legal fees) that may occur in connection with the use of
            this site/app. We take reasonable care and skill to ensure that the
            data provided on this site is accurate and up to date. However, we
            give no warranty of the accuracy of any information on the site and
            do not accept liability for any errors or omissions, neither do we
            accept liability for any resulting damage or loss.
          </p>
        </div>
        <div id="communications">
          <h3>Communications:</h3>
          <p>
            The entire communication with us is electronic. Every time you send
            us an email or visit our website or use our app, you are going to be
            communicating with us. You hereby consent to receive communications
            from us. If you subscribe to the news on our website or app, you are
            going to receive regular emails from us. We will continue to
            communicate with you by posting news and notices on our website and
            by sending you emails. You also agree that all notices, disclosures,
            agreements and other communications we provide to you electronically
            meet the legal requirements that such communications be in writing.
          </p>
        </div>
        <div id="law">
          <h3>Applicable Law:</h3>
          <p>
            By visiting this website or/and using the mobile app, you agree that
            the laws of Nigeria, without regard to principles of conflict laws,
            will govern these terms and conditions, or any dispute of any sort
            that might come between Masterprime Management Services limited and
            you, or its business partners and associates.
          </p>
        </div>
        <div id="disputes">
          <h3>Disputes:</h3>
          <p>
            Any dispute related in any way to your visit to this website or use
            of the mobile app to products you purchase from us shall be
            arbitrated by state or federal court [your location] and you consent
            to exclusive jurisdiction and venue of such courts.
          </p>
        </div>
        <div id="license">
          <h3>License and Site Access:</h3>
          <p>
            We grant you a limited license to access and make personal use of
            this website and mobile app. You are not allowed to download or
            modify it. This may be done only with written consent from us.
          </p>
        </div>
        <div id="membership">
          <h3>Site Membership:</h3>
          <p>
            Users must be at least 18 years of age or older to participate on
            our website or use our mobile app is made available for personal use
            only. You must provide current and accurate information (correct
            name and correct address) upon registration for our Service.
          </p>
          <p>
            By agreeing to our terms and using the site you acknowledge that any
            breach of these terms will result in any accounts involved being
            banned automatically. Any account banned on the website/ mobile app
            will result in wallet any related accounts being forfeited.
          </p>
          <p>
            Members are solely responsible for the payment and reporting of any
            local taxes that may be due in relation to any monies or prizes
            earned whilst using our Service.
          </p>
        </div>
        <div id="termination">
          <h3> Account Termination:</h3>
          <p>
            We reserve the right to refuse membership without giving a specific
            reason. Further, we reserve all rights to terminate accounts, edit
            or remove content and cancel orders in their sole discretion.
          </p>
          <p>
            If you are an owner of an account on this website or mobile app, you
            are solely responsible for maintaining the confidentiality of your
            private user details (username and password). You are responsible
            for all activities that occur under your account or password.
            business skills or practices.
          </p>
          <p>
            Users of our products, services and web site are advised to do their
            own due diligence when it comes to making business decisions and all
            information, products, and services that have been provided should
            be independently verified by your own qualified professionals. Our
            information, products, and services on Masterprime management
            services limited should be carefully considered and evaluated,
            before reaching a business decision, on whether to rely on them.
          </p>
        </div>
      </div>
    </Container>
  );
}

export default Terms;
