import React, {useState} from "react";
import ShowReceipt from "./ShowReceipt";

function TransactionDetail({ type, amount, date, status, name, reference,token }) {
  const [modalShow, setModalShow] = useState(false);
   function showModal() {
     return setModalShow(true);
   }
  return (
    <div>
      <ShowReceipt
        type={type}
        amount={amount}
        date={date}
        reference={reference}
        status={status}
        name={name}
        token={token}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div onClick={showModal} className="transaction-detail">
        <div style={styles.txnDetailsContainer}>
          <p style={styles.txnName}>{name}</p>
          <p style={styles.date}>{date}</p>
        </div>
        <div style={{ marginRight: 10 }}>
          <p style={styles.txnVal}>
            {type === "DEBIT" ? `- ${amount}` : type === "CREDIT" ? `+ ${amount}` : null}
          </p>
          <p
            style={
              (styles.txnVal,
              {
                color: `${
                  status === "PENDING"
                    ? "#d888b3"
                    : status === "APPROVED"
                    ? "lime"
                    : "red"
                }`,
                textTransform: "lowercase",
              })
            }
          >
            {status}
          </p>
        </div>

        <div>
          <svg
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#07245f"
            viewBox="24 24"
          >
            <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
          </svg>
          <div></div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  txnContainer: {
    backgroundColor: "#fff",
    padding: 15,
    flexDirection: "column",
    alignItems: "center",
 
    borderRadius: 10
  },
  txnDetailsContainer: {
    marginBottom: 5,
    flex: 1,
  },
  txnName: {
    fontWeight: "bold",
    fontSize: 14,
  },
  date: {
    fontWeight: "400",
    fontSize: 14,
  },
  txnVal: {
    fontWeight: "400",
    fontSize: 14,
  },
};

export default TransactionDetail;
