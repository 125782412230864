import React, { createRef } from "react";
import { Button, Modal, Row, Col, Container } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";

import logo from "../assets/images/Logo.png";

function ShowReceipt({
  show,
  onHide,
  type,
  amount,
  date,
  status,
  name,
  reference,
  token
}) {
  const headingStyle = {
    margin: "20px auto",
    padding: "10px",
    backgroundColor: "#90EE90",
    color: "#fff",
    fontSize: "18px",
    fontWeight: "400",
  };
  // const copyCodeToClipboard = () => {
  
  //   document.execCommand("copy")
  //   toast.success("copied to clipboard")
  // }
  return (
    <div>
            {/* <Toaster
        position="bottom-center"
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
          error: {
            duration: 3000,
            theme: {
              primary: "red",
              secondary: "black",
            },
          },
        }}
      /> */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={onHide}
      >
        <Modal.Body>
          <Modal.Title
            className="text-center"
            id="contained-modal-title-vcenter"
          >
            <div
              style={{
                display: "flex",
                justigfyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 10,
                }}
              >
                <img style={styles.logo} src={logo} alt="company Logo" />
                <h2 style={styles.brand}>Masterprime</h2>
              </div>
              <h2 style={styles.headingText}>Transaction Details</h2>
            </div>
          </Modal.Title>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 30,
            }}
          >
            <div style={styles.textContainer}>
              <p style={styles.titleText}>Date:</p>
              <p style={styles.detailsText}>{date}</p>
            </div>
            <div style={styles.textContainer}>
              <p style={styles.titleText}>Transaction name</p>
              <p style={styles.detailsText}>{name}</p>
            </div>
            <div style={styles.textContainer}>
              <p style={styles.titleText}>Amount</p>
              <p style={styles.detailsText}>
                {type === "DEBIT"
                  ? `- ${amount}`
                  : type === "CREDIT"
                  ? `+ ${amount}`
                  : null}
              </p>
            </div>
            <div style={styles.textContainer}>
              <p style={styles.titleText}>Reference number</p>
              <p style={styles.referenceNum}>{reference}</p>
            </div>
            {token && (
              <div style={styles.textContainer}>
                <p style={styles.titleText}>Token</p>
                <p style={{...styles.referenceNum, cursor:"pointer"}}>{token}</p>
              </div>
            )}
            <div style={styles.textContainer}>
              <p style={styles.titleText}>Status</p>
              <p
                style={
                  (styles.detailsText,
                  {
                    color: `${
                      status === "PENDING"
                        ? "#d888b3"
                        : status === "APPROVED"
                        ? "lime"
                        : "red"
                    }`,
                    textTransform: "lowercase",
                  })
                }
              >
                {status}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

const styles = {
  brand: {
    fontWeight: 500,
    fontSize: 20,
    color: "#dc143c",
    letterSpacing: 0.5,
  },
  logo: {
    width: 50,
    height: 40,
    marginRight: 5,
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    width: "90%",
    alignItem: "center",
  },
  headingText: {
    fontSize: 20,
    fontWeight: "400",
    letterSpacing: 0.5,
  },
  titleText: {
    flex: 1,
    fontSize: 14,
    fontWeight: "400",
  },
  detailsText: {
    fontSize: 14,
    fontWeight: "400",
  },
  referenceNum: {
    width: "50%",
    fontSize: 14,
    fontWeight: "400",
    overflowWrap: "break-word",
  },
};

export default ShowReceipt;
