import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

function Success(props) {
  useEffect(() => {
    props.history.push("/auth/login");
    setTimeout(useEffect, 60000);
  });
  return (
    <div>
      <Container>
        <h1>Account verified</h1>
        <h3>
          If you're not redirected please click{" "}
          <Link to="/auth/login">here</Link>
        </h3>
      </Container>
    </div>
  );
}

export default Success;
