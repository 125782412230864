import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../components/AppContext";
import {
  Col,
  Form,
  Button,
  InputGroup,
  DropdownButton,
  Dropdown,
  Spinner,
  Container,
} from "react-bootstrap";
import axios from "axios";
import Loader from "../../components/spinner/Loader";
import DismissableAlert from "../../components/DismissableAlert";
import SuccessAlert from "../../components/SuccessAlert";
import { Base } from "../../components/Base";

function MyProfile(props) {
  const [user, setUser] = useContext(AppContext);
  const [profile, setProfile] = useState("");
  const [status, setStatus] = useState({
    loading: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  let TOKEN;
  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = sessionStorage.getItem("user");
      //console.log(loggedInUser);
      let foundUser;
      if (loggedInUser) {
        foundUser = await JSON.parse(loggedInUser);
        setUser(foundUser);
        TOKEN = foundUser.data.token;
        try {

          const response = await axios.get(
            `${Base}/user/profile/view`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${TOKEN}`,
              },
            }
          );
  
          setProfile(response.data);
        } catch(err) {
          if (err.message === "Request failed with status code 401") {
            sessionStorage.removeItem("user");
            props.history.push("/auth/login");
          }
        }
      } else {
        props.history.push("/auth/login");
      }
      //console.log(user);
    };
    fetchData();
    //console.log(profile);
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    try {
      setProfile({ data: { phone: e.target.value } });
    } catch (err) {
      throw new Error("this is an error");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ loading: true });

    const loggedInUser = await sessionStorage.getItem("user");
    //console.log(loggedInUser);
    let foundUser;
    if (loggedInUser) {
      foundUser = await JSON.parse(loggedInUser);
    }

    const TOKEN = foundUser.data.token;
    try {
      //setProfile({})

      const response = await axios.put(
        `${Base}/user/profile/update`,
        { phone: profile.data.phone },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${TOKEN}`,
          },
        }
      );
      if (response.data.status === true) {
        setSuccessMessage(response.data.message);
        setStatus({ loading: false });
        console.log(response.data);
      } else {
        setErrorMessage(response.data.message);
        setStatus({ loading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      {profile ? (
        <Col>
          <div style={{ marginBottom: "30px" }}></div>
          <h2
            style={{
              backgroundColor: "#4267b2",
              padding: "10px",
              color: "#fff",
              fontWeight: "300",
              fontSize: "22px",
            }}
            className="text-center"
          >
            My Profile
          </h2>
          <Form onSubmit={handleSubmit}>
            {errorMessage && (
              <DismissableAlert
                handleClose={() => setErrorMessage("")}
                message={errorMessage}
              />
            )}
            {successMessage && (
              <SuccessAlert
                handleClose={() => setSuccessMessage("")}
                message={successMessage}
              />
            )}
            <Form.Group
              style={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "bold",
                color: "lime",
              }}
            >
              <Form.Text>
                You can only update you phone number in the form below
              </Form.Text>
            </Form.Group>

            <Form.Group>
              <Form.Control
                name="firstname"
                type="text"
                size="lg"
                readOnly
                value={profile.data.firstname}
              />
            </Form.Group>

            <Form.Group>
              <Form.Control
                name="lastname"
                type="text"
                size="lg"
                readOnly
                value={profile.data.lastname}
              />
            </Form.Group>

            <InputGroup className="mb-3">
              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                id="input-group-dropdown-1"
                title="+234"
              >
                <Dropdown.Item>+234</Dropdown.Item>
              </DropdownButton>
              <Form.Control
                name="phone"
                type="text"
                maxLength={11}
                inputMode="tel"
                pattern="\d*"
                size="lg"
                placeholder="Phone number"
                onChange={handleChange}
                value={profile.data.phone}
                required
              />
            </InputGroup>

            <Form.Group>
              <Form.Control
                name="email"
                type="email"
                size="lg"
                readOnly
                value={profile.data.email}
              />
            </Form.Group>

            <Button
              size="sm"
              type="submit"
              disabled={status.loading && true}
              style={{ backgroundColor: "#4267b2" }}
              block
            >
              {status.loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Update"
              )}
            </Button>
          </Form>
        </Col>
      ) : (
        <Loader />
      )}
    </Container>
  );
}

export default MyProfile;
