import React, { useContext, useEffect } from "react";
import { Col, Row, Button, Jumbotron } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../components/AppContext";
import Footer from "../components/Footer";
import appStore from "../assets/store/appstore.png";
import playStore from "../assets/store/playstore.png";
import first from "../assets/home/first@1x.png";
import second from "../assets/home/second@1x.png";
import third from "../assets/home/third.png";
import fourth from "../assets/home/fourth@1x.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Close from "./Close";

export default function Home(props) {
  const [user, setUser] = useContext(AppContext);

  useEffect(() => {
    AOS.init();
    const fetchUser = async () => {
      const loggedInUser = sessionStorage.getItem("user");
      console.log(loggedInUser);
      if (loggedInUser) {
        const foundUser = JSON.parse(loggedInUser);
        setUser(foundUser);
      } else {
        props.history.push("/");
      }
    };
    fetchUser();
  }, []);
  return (
    <div className="Home">
      <Jumbotron className="FirstRow">
        <div className="Content">
          <h2 data-aos-duration="3000" data-aos="fade-down">
            Easy and better way to pay bills
          </h2>

          <Button
            href="/auth/sign-up"
            style={{ backgroundColor: "#4267b2", border: "none" }}
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Create a free account
          </Button>
          <div
            data-aos="fade-up"
            data-aos-duration="600"
            data-aos-delay="300"
            style={{ margin: "19px auto" }}
          >
            <a to="#">
              <img src={appStore} alt="appStore" height="47px" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.mservices.payment"
              target="_blank"
              style={{ margin: "10px" }}
            >
              <img src={playStore} alt="playStore" height="47px" />
            </a>
          </div>
        </div>
      </Jumbotron>
      <main>
        <Row className="FirstDiv">
          <Col className="Content">
            <h2 data-aos-duration="1000" data-aos="fade-down">
              Transact across Nigeria from the comfort of your home
            </h2>
            <p>
              Buy airtime / data, pay bills, send money, fund wallet with just a
              click on your phone.
            </p>
          </Col>
          <Col></Col>

          <Col className="Firstimage">
            <img
              data-aos-duration="1000"
              data-aos="fade-up"
              src={first}
              alt="firstImage"
            />
          </Col>
        </Row>
        <Row className="SecondDiv">
          <Col className="Content">
            <h2 data-aos-duration="1000" data-aos="fade-down">
              Buy Airtime / Data
            </h2>
            <p>
              Buy and send airtime / data to loved ones, friends, collegues and
              customers from your prime wallet.
            </p>
          </Col>
          <Col></Col>
          <Col className="Secondimage">
            <img
              data-aos-duration="1000"
              data-aos="fade-up"
              src={second}
              alt="secondImage"
            />
          </Col>
        </Row>

        <Row className="FourthDiv">
          <Col className="Content">
            <h2 data-aos-duration="1000" data-aos="fade-down">
              Pay Bills
            </h2>
            <p>
              Pay all your bills, cable tv, electricity comfortably from your
              smartphone
            </p>
          </Col>

          <Col></Col>

          <Col className="Fourthimage">
            <img
              data-aos-duration="1000"
              data-aos="fade-up"
              src={fourth}
              alt="fourthImage"
            />
          </Col>
        </Row>
      </main>
      <Footer />
    </div>
  );
}
