import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./views/Home";
import Terms from "./views/Terms";
import Login from "./auth/Login";
import SignUp from "./auth/SignUp";
import Activate from "./auth/Activate";
import Dashboard from "./views/dashboard/Dashboard";
import Error from "./views/404";
import Recovery from "./views/Recovery";
import PasswordReset from "./views/PasswordReset";
import Header from "./components/Header";
import ReActivate from "./auth/ReActivate";
import Success from "./views/Success";
import MyProfile from "./views/dashboard/MyProfile";
import { AppContext, AppProvider } from "./components/AppContext";
import BuyAirtime from "./views/dashboard/BuyAirtime";
import ElectricityBills from "./views/dashboard/ElectricityBills";
import Settings from "./views/dashboard/Settings";
import Internet from "./views/dashboard/Internet";
import AccountSummary from "./views/dashboard/AccountSummary";
import CableTV from "./views/dashboard/CableTV";
import Wallet from "./views/dashboard/Wallet";
import TopBar from "./views/dashboard/TopBar";
import BuyData from "./views/dashboard/BuyData";
import Fund from "./views/dashboard/Fund";
import {Base} from "./components/Base"
import Close from "./views/Close";

function App(props) {
  
   
  return (
    <AppProvider>
      <Header history={props.history} />
      <TopBar history={props.history} />

      <Router>
        <Switch>
          <Route exact from="/" render={(props) => <Home {...props} />} />
          <Route
            path="/auth/login"
            exact
            render={(props) => <Login {...props} />}
          />
          <Route
            path="/auth/sign-up"
            exact
            render={(props) => <SignUp {...props} />}
          />
          <Route
            path="/auth/activate"
            exact
            render={(props) => <Activate {...props} />}
          />
          <Route
            path="/legal/terms"
            exact
            render={(props) => <Terms {...props} />}
          />
          <Route
            path="/success"
            exact
            render={(props) => <Success {...props} />}
          />

          <Route
            path="/auth/activate/resend"
            exact
            render={(props) => <ReActivate {...props} />}
          />
          <Route
            path="/dashboard"
            exact
            render={(props) => <Dashboard {...props} />}
          />
          <Route
            path="/dashboard"
            exact
            render={(props) => <Wallet {...props} />}
          />
          <Route
            path="/dashboard/fund"
            exact
            render={(props) => <Fund {...props} />}
          />

          <Route
            path="/dashboard/buyairtime"
            exact
            render={(props) => <BuyAirtime {...props} />}
          />
          <Route
            path="/dashboard/buydata"
            exact
            render={(props) => <BuyData {...props} />}
          />
          <Route
            path="/dashboard/electicity-bill"
            exact
            render={(props) => <ElectricityBills {...props} />}
          />
          {/* <Route
            path="/dashboard/internet"
            exact
            render={(props) => <Internet {...props} />}
          /> */}
          <Route
            path="/dashboard/profile"
            exact
            render={(props) => <MyProfile {...props} />}
          />
          <Route
            path="/dashboard/settings"
            exact
            render={(props) => <Settings {...props} />}
          />
          <Route
            path="/dashboard/summary"
            exact
            render={(props) => <AccountSummary {...props} />}
          />
          <Route
            path="/dashboard/cabletv"
            exact
            render={(props) => <CableTV {...props} />}
          />

          <Route
            path="/password_recovery"
            exact
            render={(props) => <Recovery {...props} />}
          />

          <Route
            path="/password_reset"
            exact
            render={(props) => <PasswordReset {...props} />}
          />
          <Route
            path="/close"
            exact
            render={(props) => <Close {...props} />}
          />

          <Route path="*" exact render={(props) => <Error {...props} />} />
        </Switch>
      </Router>
    </AppProvider>
  );
}

export default App;
