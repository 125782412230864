import React, { useContext, useEffect, useState } from "react";
import Main from "./Wallet";
import { AppContext } from "../../components/AppContext";
import axios from 'axios'
import {Base} from '../../components/Base'


export default function Dashboard(props) {
 const [user, setUser] = useState("");
 useEffect(() => {
   let foundUser;
   const fetchUser = async () => {
     const loggedInUser = sessionStorage.getItem("user");
     console.log(loggedInUser);
     if (loggedInUser) {
       foundUser = JSON.parse(loggedInUser);
       setUser(foundUser);
       const TOKEN = foundUser.data.token;
       try {
         const response = await axios.get(`${Base}/user/profile/view`, {
           headers: {
             Accept: "application/json",
             Authorization: `Bearer ${TOKEN}`,
           },
         });
       } catch (err) {
         if (err.message === "Request failed with status code 401") {
           sessionStorage.removeItem("user");
           setUser("");
           props.history.push("/auth/login");
         }
       }
     }
   };
   fetchUser();
 }, []);
  

  

  return (
    <div className="DashStyle">
      <Main history={props.history} />
      
    </div>
  );
}
