import React, { useState, createRef, useEffect } from "react";
import {
  Form,
  InputGroup,
  Modal,
  Alert,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import ekedc from "../../assets/billers/ekedc.jpg";
import ikeja from "../../assets/billers/ikeja.jpg";
import phed from "../../assets/billers/phed.jpg";
import eedc from "../../assets/billers/eedc.png";
import kedco from "../../assets/billers/kedco.png";
import ibedc from "../../assets/billers/ibedc.png";
import aedc from "../../assets/billers/aedc.png";
import axios from "axios";
import DismissableAlert from "../../components/DismissableAlert";
import SuccessAlert from "../../components/SuccessAlert";
import Skeleton from "react-loading-skeleton";
import { Base } from "../../components/Base";
import toast, { Toaster } from "react-hot-toast";

function ElectricityBills(props) {
  let foundUser;
  let token;
  const [balance, setBalance] = useState("");
  const [TOKEN, setTOKEN] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const [returnedValues, setReturnedValue] = useState("");
  const [returnedError, setReturnedError] = useState("");
  const [fetcher, setFetcher] = useState(false);
  const [returnedToken, setReturnedToken] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const [clicked, setClicked] = useState({
    ekedc: false,
    ikeja: false,
    phed: false,
    eedc: false,
    kedco: false,
    ibedc: false,
    aedc: false,
  });

  useEffect(() => {
    setReturnedValue("");
    setReturnedError("");
    setFetcher(false);
    setModalShow(false)
    setReturnedToken("")
    const fetchUser = async () => {
      const loggedInUser = sessionStorage.getItem("user");
      if (loggedInUser) {
        setClicked({ ekedc: true });
        foundUser = JSON.parse(loggedInUser);
        token = foundUser.data.token;
        setTOKEN(foundUser.data.token);
        try {

          const wallet = await axios.get(`${Base}/user-wallet/balance`, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          setBalance(wallet.data.data.balance);
        } catch(err) {
          if (err.message === "Request failed with status code 401") {
            sessionStorage.removeItem("user");
            props.history.push("/auth/login");
          }
        }
      } else {
        props.history.push("/auth/login");
      }
    };
    fetchUser();
  }, [props.history]);
  const phoneRef = createRef();
  const amountRef = createRef();
  const meterRef = createRef();
  const selectRef = createRef();

  const clickedClassekedc = clicked.ekedc ? "Selected" : "";
  const clickedClassikeja = clicked.ikeja ? "Selected" : "";
  const clickedClassphed = clicked.phed ? "Selected" : "";
  const clickedClasseedc = clicked.eedc ? "Selected" : "";
  const clickedClassaedc = clicked.aedc ? "Selected" : "";
  const clickedClassibedc = clicked.ibedc ? "Selected" : "";
  const clickedClasskedco = clicked.kedco ? "Selected" : "";
  const handleClick = (name) => {
    switch (name) {
      case "ekedc":
        setClicked({ ekedc: true });
        break;
      case "ikeja":
        setClicked({ ikeja: true });
        break;
      case "phed":
        setClicked({ phed: true });
        break;
      case "eedc":
        setClicked({ eedc: true });
        break;
      case "ibedc":
        setClicked({ ibedc: true });
        break;
      case "kedco":
        setClicked({ kedco: true });
        break;
      case "aedc":
        setClicked({ aedc: true });
        break;
      default:
        setClicked("");
    }
  };

  const handleChange = async (e) => {
    const data = {
      meter_number: meterRef.current.value,
    };
    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    if (e.target.value.length >= 10) {
      setFetcher(true);
      if (clicked.ikeja) {
        setFetcher(true);
        try {
          setFetcher(true);
          const ikejaResponse = await axios.post(
            `${Base}/electricity/verify/${selectRef.current.value}`,
            data,
            { headers }
          );
          console.log(ikejaResponse.data);
          if (ikejaResponse.data.status) {
            setFetcher(false);
            setReturnedValue(ikejaResponse.data);
            setReturnedError("");
          } else {
            setReturnedValue("");
            setFetcher(false);
            setReturnedError(ikejaResponse.data);
          }
        } catch (err) {
          console.log(err);
          if (err.message === "Request failed with status code 401") {
            sessionStorage.removeItem("user");
            props.history.push("/auth/login");
          }
        }
      } else if (clicked.ekedc) {
        try {
          setFetcher(true);
          const ekedcResponse = await axios.post(
            `${Base}/electricity/verify/${selectRef.current.value}`,
            data,
            { headers }
          );
          console.log(ekedcResponse.data);
          if (ekedcResponse.data.status) {
            setFetcher(false);
            setReturnedValue(ekedcResponse.data);
            setReturnedError("");
          } else {
            setReturnedValue("");
            setFetcher(false);
            setReturnedError(ekedcResponse.data);
          }
        } catch (err) {
          console.log(err);
          if (err.message === "Request failed with status code 401") {
            sessionStorage.removeItem("user");
            props.history.push("/auth/login");
          }
        }
      } else if (clicked.phed) {
        try {
          setFetcher(true);
          console.log(selectRef.current.value);
          const phedResponse = await axios.post(
            `${Base}/electricity/verify/${selectRef.current.value}`,
            data,
            { headers }
          );
          console.log(phedResponse.data);
          if (phedResponse.data.status) {
            setFetcher(false);
            setReturnedValue(phedResponse.data);
            setReturnedError("");
          } else {
            setReturnedValue("");
            setFetcher(false);
            setReturnedError(phedResponse.data);
          }
        } catch (err) {
          console.log(err);
          if (err.message === "Request failed with status code 401") {
            sessionStorage.removeItem("user");
            props.history.push("/auth/login");
          }
        }
      } else if (clicked.eedc) {
        try {
          setFetcher(true);
          const eedcResponse = await axios.post(
            `${Base}/electricity/verify/${selectRef.current.value}`,
            data,
            { headers }
          );
          console.log(eedcResponse.data);
          if (eedcResponse.data.status) {
            setFetcher(false);
            setReturnedValue(eedcResponse.data);
            setReturnedError("");
          } else {
            setReturnedValue("");
            setFetcher(false);
            setReturnedError(eedcResponse.data);
          }
        } catch (err) {
          console.log(err);
          if (err.message === "Request failed with status code 401") {
            sessionStorage.removeItem("user");
            props.history.push("/auth/login");
          }
        }
      } else if (clicked.kedco) {
        try {
          setFetcher(true);
          const kedcoResponse = await axios.post(
            `${Base}/electricity/verify/${selectRef.current.value}`,
            data,
            { headers }
          );
          console.log(kedcoResponse.data);
          if (kedcoResponse.data.status) {
            setFetcher(false);
            setReturnedValue(kedcoResponse.data);
            setReturnedError("");
          } else {
            setReturnedValue("");
            setFetcher(false);
            setReturnedError(kedcoResponse.data);
          }
        } catch (err) {
          console.log(err);
        }
      } else if (clicked.ibedc) {
        try {
          setFetcher(true);
          const ibedcResponse = await axios.post(
            `${Base}/electricity/verify/${selectRef.current.value}`,
            data,
            { headers }
          );
          console.log(ibedcResponse.data);
          if (ibedcResponse.data.status) {
            setFetcher(false);
            setReturnedValue(ibedcResponse.data);
            setReturnedError("");
          } else {
            setReturnedValue("");
            setFetcher(false);
            setReturnedError(ibedcResponse.data);
          }
        } catch (err) {
          console.log(err);
          if (err.message === "Request failed with status code 401") {
            sessionStorage.removeItem("user");
            props.history.push("/auth/login");
          }
        }
      } else if (clicked.aedc) {
        try {
          setFetcher(true);
          const aedcResponse = await axios.post(
            `${Base}/electricity/verify/${selectRef.current.value}`,
            data,
            { headers }
          );
          console.log(aedcResponse.data);
          if (aedcResponse.data.status) {
            setFetcher(false);
            setReturnedValue(aedcResponse.data);
            setReturnedError("");
          } else {
            setReturnedValue("");
            setFetcher(false);
            setReturnedError(aedcResponse.data);
          }
        } catch (err) {
          console.log(err);
          if (err.message === "Request failed with status code 401") {
            sessionStorage.removeItem("user");
            props.history.push("/auth/login");
          }
        }
      }
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setValidated(true);
    const data = {
      meter_number: meterRef.current.value,
      amount: amountRef.current.value,
      customer_name: returnedValues.data.name,
      contact_type: "landlord",
      customer_address: returnedValues.data.address,
      customer_number: returnedValues.data.customernumber
        ? returnedValues.data.customernumber
        : "",
    };

    // console.log(data);
    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    try {
      const response = await axios.post(
        `${Base}/electricity/buy/${selectRef.current.value}`,
        data,
        { headers }
      );
      // console.log(response.data);
      if (response.data.status) {
         toast.success(response?.data?.message);
        const wallet = await axios.get(`${Base}/user-wallet/balance`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${TOKEN}`,
          },
        });
        setBalance(wallet.data.data.balance);
        setSuccessMessage(response.data.message);
        setValidated(false);
        setReturnedValue("");
        if (response.data.data.token) {
          setReturnedToken(response.data.data.token);
        }
        e.target.reset();
      } else {
        toast.error(response?.data?.message);
        setErrorMessage(
          response.data.message
        );
      }
      setValidated(false);
    } catch (err) {
      setValidated(false);
      setErrorMessage(err.message);
      console.log(err);
      toast.error(err.message);
      if (err.message === "Request failed with status code 401") {
        sessionStorage.removeItem("user");
        props.history.push("/auth/login");
      }
    }
  }

  return (
    <div className="Purchase">
      <h1 className="text-center">Pay for Electricity</h1>
      <Toaster
        position="bottom-center"
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
          error: {
            duration: 3000,
            theme: {
              primary: "red",
              secondary: "black",
            },
          },
        }}
      />
      <Form onSubmit={handleSubmit}>
        <Form.Label>Select provider</Form.Label>
        <div className="BillClass">
          <span
            onClick={() => handleClick("ekedc")}
            className={`Bills ${clickedClassekedc}`}
          >
            <img src={ekedc} alt="ekedc" />
          </span>
          <span
            onClick={() => handleClick("ikeja")}
            className={`Bills ${clickedClassikeja}`}
          >
            <img src={ikeja} alt="ikeja" />
          </span>
          <span
            onClick={() => handleClick("phed")}
            className={`Bills ${clickedClassphed}`}
          >
            <img src={phed} alt="phed" />
          </span>
          <span
            onClick={() => handleClick("kedco")}
            className={`Bills ${clickedClasskedco}`}
          >
            <img src={kedco} alt="kedco" />
          </span>
          <span
            onClick={() => handleClick("eedc")}
            className={`Bills ${clickedClasseedc}`}
          >
            <img src={eedc} alt="eedc" />
          </span>
          <span
            onClick={() => handleClick("ibedc")}
            className={`Bills ${clickedClassibedc}`}
          >
            <img src={ibedc} alt="ibedc" />
          </span>
          <span
            onClick={() => handleClick("aedc")}
            className={`Bills ${clickedClassaedc}`}
          >
            <img src={aedc} alt="aedc" />
          </span>
        </div>

        <Row>
          <Col>
            <Form.Group style={{ textAlign: "start" }}>
              <Form.Label>Subscription plan</Form.Label>
              <div style={{ width: "100%" }}></div>
              <Form.Control size="lg" as="select" ref={selectRef}>
                {clicked.ikeja && (
                  <>
                    <option value="11">Prepaid</option>
                    <option value="10">Postpaid</option>
                  </>
                )}
                {clicked.ibedc && <option value="12">Prepaid</option>}
                {clicked.ekedc && (
                  <>
                    <option value="13">Prepaid</option>
                    <option value="14">Postpaid</option>
                  </>
                )}
                {clicked.phed && (
                  <>
                    <option value="16">Prepaid</option>
                    <option value="15">Postpaid</option>
                  </>
                )}
                {clicked.kedco && <option value="20">Prepaid</option>}
                {clicked.eedc && (
                  <>
                    <option value="21">Prepaid</option>
                    <option value="22">Postpaid</option>
                  </>
                )}
                {clicked.aedc && (
                  <>
                    <option value="24">Prepaid</option>
                    <option value="25">Postpaid</option>
                  </>
                )}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group style={{ textAlign: "start" }}>
              <Form.Label>Phone number</Form.Label>
              <Form.Control
                size="lg"
                ref={phoneRef}
                type="text"
                maxLength={11}
                inputMode="tel"
                pattern="\d*"
                placeholder="Phone number"
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group style={{ textAlign: "start" }}>
          <Form.Label>Meter number</Form.Label>
          <Form.Control
            size="lg"
            ref={meterRef}
            type="text"
            onChange={handleChange}
            placeholder="Enter meter number"
            required
          />
        </Form.Group>
        {fetcher && <Skeleton />}
        {returnedValues.status ? (
          <Alert variant="success">{returnedValues.data.name}</Alert>
        ) : returnedError.status === false ? (
          <Alert variant="danger">verification failed</Alert>
        ) : null}

        <InputGroup>
          <Form.Label>Amount you want to recharge</Form.Label>
          <div style={{ width: "100%" }}></div>
          <InputGroup.Prepend>
            <InputGroup.Text>NGN</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            size="lg"
            ref={amountRef}
            placeholder="Amount (minimum is 500)"
            inputMode="decimal"
            required
          />
        </InputGroup>
        <Form.Text id="passwordHelpBlock" muted style={{ color: "#3d6be2" }}>
          your payment is free
        </Form.Text>
        <div
          style={{
            textAlign: "start",
            color: "blue",
            margin: "10px 0",
          }}
        >
          Balance: NGN {balance && balance}
        </div>
        <Button
          type="submit"
          size="md"
          block
          style={{ backgroundColor: "#4267b2" }}
          disabled={returnedValues && !validated ? false : true}
        >
          {validated ? "Loading...." : "Buy power now"}
        </Button>
      </Form>
      {returnedToken && (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Success!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Your token</h4>
            <p>{returnedToken}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setModalShow(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default ElectricityBills;
