import React, { useContext, useEffect, useState } from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import Logo from "./Logo";
import { AppContext } from "./AppContext";
import { BoxArrowLeft } from "react-bootstrap-icons";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { Base } from "./Base";


export default function Header(props) {
  const [user, setUser] = useContext(AppContext);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let foundUser;
    const fetchUser = async () => {
      const loggedInUser = sessionStorage.getItem("user");
      console.log(loggedInUser);
      if (loggedInUser) {
        foundUser = JSON.parse(loggedInUser);
        setUser(foundUser);
        const TOKEN = foundUser.data.token;
        setLoading(true);
        try {
          const response = await axios.get(
            `${Base}/user/profile/view`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${TOKEN}`,
              },
            }
          );

          if (response.status === 200) {
            setLoading(false);
            setName(response.data.data);
          }
        } catch (err) {
          console.log(err.message);
          if(err.message === "Request failed with status code 401") {
            sessionStorage.removeItem("user")
            setUser("");
            props.history.push("/auth/login");
          }
        }
      }
    };
    fetchUser();
  }, [props.history, setUser]);

  const handleLogout = () => {
    setUser("");
    sessionStorage.removeItem("user");
  };

  return (
    <div style={{ marginBottom: "75px" }}>
      <Navbar
        collapseOnSelect
        expand="lg"
        style={{ backgroundColor: "#fff" }}
        fixed="top"
      >
        <Navbar.Brand
          className="float-left"
          href={user.status ? "/dashboard" : "/"}
        >
          <Logo />
          <span id="brand">Masterprime</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            activeKey={props.history.location.pathname}
            className="ml-auto text-center navStyle"
          >
            {user.status ? (
              <React.Fragment>
                <Nav.Link
                  href="/dashboard/profile"
                  className="d-none d-md-block d-lg-block text-center"
                  style={{
                    margin: "0 10px",
                    backgroundColor: "transparent",
                    color: "#000",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2c3.032 0 5.5 2.467 5.5 5.5 0 1.458-.483 3.196-3.248 5.59 4.111 1.961 6.602 5.253 7.482 8.909h-19.486c.955-4.188 4.005-7.399 7.519-8.889-1.601-1.287-3.267-3.323-3.267-5.61 0-3.033 2.468-5.5 5.5-5.5zm0-2c-4.142 0-7.5 3.357-7.5 7.5 0 2.012.797 3.834 2.086 5.182-5.03 3.009-6.586 8.501-6.586 11.318h24c0-2.791-1.657-8.28-6.59-11.314 1.292-1.348 2.09-3.172 2.09-5.186 0-4.143-3.358-7.5-7.5-7.5z" />
                  </svg>{" "}
                  {loading && (
                    <Skeleton
                      width={32}
                      style={{ position: "absolute", right: "120px" }}
                    />
                  )}
                  <span style={{ color: "#000" }}>
                    {name && name.firstname}
                  </span>
                </Nav.Link>
                <Button
                  className="d-none d-md-block d-lg-block"
                  onClick={handleLogout}
                  href="/"
                  style={{ margin: "0 10px" }}
                >
                  <BoxArrowLeft
                    className="d-none d-md-inline d-lg-inline"
                    color="red"
                    size={20}
                  />{" "}
                  Logout
                </Button>
                <Nav.Link
                  className="d-xs d-md-none d-lg-none"
                  href="/dashboard"
                >
                  Wallet
                </Nav.Link>
                <Nav.Link
                  href="/dashboard/buyairtime"
                  className="d-xs d-md-none d-lg-none"
                >
                  Buy Airtime
                </Nav.Link>
                <Nav.Link
                  href="/dashboard/buydata"
                  className="d-xs d-md-none d-lg-none"
                >
                  Buy Data
                </Nav.Link>
                <Nav.Link
                  href="/dashboard/cabletv"
                  className="d-xs d-md-none d-lg-none"
                >
                  Cable TV
                </Nav.Link>
                <Nav.Link
                  href="/dashboard/internet"
                  className="d-xs d-md-none d-lg-none"
                >
                  Internet
                </Nav.Link>
                <Nav.Link
                  href="/dashboard/electicity-bill"
                  className="d-xs d-md-none d-lg-none"
                >
                  Electricity Bill
                </Nav.Link>
                <Nav.Link
                  href="/dashboard/profile"
                  className="d-xs d-md-none d-lg-none"
                >
                  My profile
                </Nav.Link>

                <Nav.Link
                  href="/dashboard/summary"
                  className="d-xs d-md-none d-lg-none"
                >
                  Account Summary
                </Nav.Link>
                <Nav.Link
                  href="/dashboard/settings"
                  className="d-xs d-md-none d-lg-none"
                >
                  Settings
                </Nav.Link>
                <Nav.Link
                  className="d-xs d-md-none d-lg-none"
                  onClick={handleLogout}
                  href="/"
                  style={{ backgroundColor: "#4267b2" }}
                >
                  <BoxArrowLeft
                    className="d-xs d-md-none d-lg-none"
                    color="red"
                    size={20}
                  />{" "}
                  Logout
                </Nav.Link>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Nav.Link href="/auth/login">Login</Nav.Link>
                <Nav.Link
                  href="/auth/sign-up"
                  style={{ backgroundColor: "#4267b2", color: "#fff" }}
                >
                  Create a free account
                </Nav.Link>
              </React.Fragment>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
