import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import TransactionDetail from "../../components/TransactionDetail";
import { Base } from "../../components/Base";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import ShowReceipt from "../../components/ShowReceipt";

function AccountSummary(props) {
  let foundUser;
  const [recentTransactions, setRecentTransactions] = useState(false);
  const [loading, setLoading] = useState(false);
  const txn = React.createRef();

  useEffect(() => {
    const fetchUser = async () => {
      const loggedInUser = sessionStorage.getItem("user");
      if (loggedInUser) {
        foundUser = JSON.parse(loggedInUser);
        const TOKEN = foundUser.data.token;
        setLoading(true);
        try {
          const transactions = await axios.get(
            `${Base}/user-transaction-logs`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${TOKEN}`,
              },
            }
          );

          if (transactions.status) {
            setLoading(false);
            console.log(transactions.data.data)
            setRecentTransactions(transactions.data.data);
          } else {
            setRecentTransactions("");
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        props.history.push("/auth/login");
      }
    };
    fetchUser();
  }, [props.history]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <Container>
      <div className="RecentTxn">
        {loading && <Skeleton height={100} count={10} />}
        {recentTransactions &&
          recentTransactions?.map((transaction) => (
            <TransactionDetail
              type={transaction?.transaction_type}
              amount={numberWithCommas(transaction.amount)}
              date={transaction?.transaction_date}
              key={transaction?.transaction_ref}
              reference={transaction?.transaction_ref}
              status={transaction?.status}
              name={transaction?.transaction_name}
              token={transaction?.token}
            />
          ))}
        {/* <Button variant="light">More</Button> */}
        {Object.keys(recentTransactions).length === 0 ? (
          <p
            style={{
              margin: "10px auto",
              textAlign: "center",
              fontWeight: "200",
              fontSize: "13px",
            }}
          >
            No transaction
          </p>
        ) : null}
      </div>
    </Container>
  );
}

export default AccountSummary;
