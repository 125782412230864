import React from 'react'
import logo from '../assets/images/Logo.png'

export default function Logo() {
    return (
        <div>
            <img src={logo} alt="company Logo"id="logo" />
        </div>
    )
}
