import React, { createRef, useEffect, useState } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import axios from "axios";
import DismissableAlert from "../../components/DismissableAlert";
import { Base } from "../../components/Base";
function Fund(props) {
  const amountRef = createRef("");
  let foundUser;
  const [TOKEN, setTOKEN] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [completed, setCompleted] = useState(false);
  useEffect(() => {
    setErrorMessage("");
    const fetchUser = async () => {
      const loggedInUser = sessionStorage.getItem("user");
      // console.log(loggedInUser);
      if (loggedInUser) {
        foundUser = JSON.parse(loggedInUser);
        setTOKEN(foundUser.data.token);
      } else {
        props.history.push("/auth/login");
      }
    };
    fetchUser();
  }, [props.history]);

  const handleChange = () => {
    if (amountRef.current.value > 90) {
      setCompleted(true);
    } else {
      setCompleted(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { amount: `${amountRef.current.value}` };
    // console.log(props.history);
    e.target.blur();
    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };

    try {
      setLoading(true);
      const response = await axios.post(`${Base}/user-wallet/topup`, data, {
        headers,
      });
      console.log(response.data);
      if (response.data.status) {
        e.target.reset();
        setLoading(false);
        window.open(response.data.data.link, "_blank");
        props.history.push("/dashboard");
      }
    } catch (err) {
      setLoading(false);
      setErrorMessage(err.message);
      if (err.message === "Request failed with status code 401") {
        sessionStorage.removeItem("user");
        props.history.push("/auth/login");
      }
    }
  };
  return (
    <div className="Purchase">
      <h1 className="text-center">Fund Wallet</h1>
      <Form onSubmit={handleSubmit}>
        {errorMessage && (
          <DismissableAlert
            handleClose={() => setErrorMessage("")}
            message={errorMessage}
          />
        )}
        <InputGroup className="mb-5">
          <Form.Label>Amount</Form.Label>
          <div style={{ width: "100%" }}></div>
          <InputGroup.Prepend>
            <InputGroup.Text>NGN</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            size="lg"
            ref={amountRef}
            onChange={handleChange}
            placeholder="Amount (minimum is 100)"
            required
          />
        </InputGroup>

        <Button
          type="submit"
          size="md"
          block
          style={{ backgroundColor: "#4267b2" }}
          disabled={completed && !loading ? false : true}
        >
          {loading ? "Loading... " : "Fund Wallet "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              id="card"
              d="M22 2h-14c-1.104 0-2 .896-2 2v4h16v3.5c0 .276-.224.5-.5.5h-1.5v2h2c1.104 0 2-.896 2-2v-8c0-1.104-.896-2-2-2zm0 3h-14v-.5c0-.276.224-.5.5-.5h13c.276 0 .5.224.5.5v.5zm-6 5h-14c-1.104 0-2 .896-2 2v8c0 1.104.896 2 2 2h14c1.104 0 2-.896 2-2v-8c0-1.104-.896-2-2-2zm-11 10h-2v-1h2v1zm3 0h-2v-1h2v1zm.32-3.377c-.383.239-.836.377-1.32.377-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5c.484 0 .937.138 1.32.377-.531.552-.857 1.3-.857 2.123 0 .824.326 1.571.857 2.123zm3.68 3.377h-2v-1h2v1zm-1-3c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zm4 3h-2v-1h2v1z"
            />
          </svg>
        </Button>
      </Form>
    </div>
  );
}

export default Fund;
