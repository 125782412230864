import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import DismissableAlert from "../components/DismissableAlert";
import SuccessAlert from "../components/SuccessAlert";
import { Base } from "../components/Base";

export default function Activate(props) {
  const [validated, setValidated] = useState(false);

  let CODE;
  useEffect(() => {
    function getCode() {
      const activationCode = localStorage.getItem("user");
    }
    getCode();
  }, [CODE]);
  const activationRef = React.createRef();
  const [status, setStatus] = useState({
    loading: false,
    errorMessege: "",
    successMessage: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);

    try {
      const data = {
        activation_code: activationRef.current.value,
      };

      const response = await axios.post(
        `${Base}/user/activate`,
        data,
        { Accept: "application/json" }
      );
      if (response.data.status === false) {
        setStatus({ errorMessage: response.data.message });
        setValidated(false);
      } else {
        e.target.reset();
        setValidated(false);

        setStatus([...status], { successMessage: response.data.message });
        localStorage.removeItem("user");
      }
    } catch (err) {
      console.log(err);
      setStatus({ errorMessage: err.message });
      setValidated(false);
    }
  };

  return (
    <div style={{ marginTop: "200px" }}>
      <Container className="m-auto text-center">
        {status.errorMessage && (
          <DismissableAlert
            handleClose={() => setStatus({ errorMessage: "" })}
            variant={true}
            message={status.errorMessage}
          />
        )}
        {status.successMessage && (
          <SuccessAlert
            handleClose={() => setStatus({ successMessage: "" })}
            variant={true}
            message={status.successMessage}
          />
        )}
        <Form className="text-center" onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Enter code sent to email</Form.Label>
            <Form.Control
              style={{
                width: "150px",
                margin: "10px auto",
                textAlign: "center",
              }}
              type="text"
              ref={activationRef}
              maxLength={6}
            ></Form.Control>
          </Form.Group>

          <Button
            style={{ width: "150px", margin: "10px auto" }}
            type="submit"
            block
            disabled={validated ? true : false}
          >
            {validated ? "loading..." : "verify"}
          </Button>
            {status.successMessage && 
          <Link to="/auth/login">Click here to login</Link>
              
            }
          <Link to="/auth/activate/resend">Resend verification code</Link>
        </Form>
      </Container>
    </div>
  );
}
