import React, { useState } from "react";
import { Alert } from "react-bootstrap";

function DismissableAlert(props) {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <Alert
        variant={"danger"}
        onClose={props.handleClose}
        dismissible
      >
        <Alert.Heading>Oops!</Alert.Heading>
        <p>{props.message}</p>
      </Alert>
    );
  }
}

export default DismissableAlert;
