import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  DropdownButton,
  Dropdown,
  Alert,
  Spinner,
} from "react-bootstrap";
import AsideImg from "../components/AsideImg";
import DismissableAlert from "../components/DismissableAlert";
import { Base } from "../components/Base";
import { PersonCircle } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const navStyle = {
  marginTop: "20px",
};

export default function SignUp(props) {
  const firstNameRef = React.createRef();
  const lastNameRef = React.createRef();
  const phoneRef = React.createRef();
  const emailRef = React.createRef();
  const passwordRef = React.createRef();
  const confirmPasswordRef = React.createRef();
  const [status, setStatus] = useState({
    loading: false,
    errorMessage: "",
  });

  useEffect(() => {
    firstNameRef.current.focus();
  }, [])
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ loading: true });
    try {
      let data = {
        firstname: firstNameRef.current.value,
        lastname: lastNameRef.current.value,
        phone: phoneRef.current.value,
        email: emailRef.current.value,
        password: passwordRef.current.value,
        confirm_password: confirmPasswordRef.current.value,
      };

      const response = await axios.post(
        `${Base}/user/register`,
        data,
        { Accept: "application/json" }
      );

      if (response.data.status === false) {
        setStatus({ errorMessage: response.data.message });
      } else {
        props.history.push("/auth/activate");
        localStorage.setItem("user", JSON.stringify(response.data));
      }
    } catch (err) {
      setStatus({ loading: false });
      setStatus({ errorMessage: err.message });
    }
  };

  return (
    <div className={navStyle}>
      <Container fluid>
        <div style={{ marginBottom: "20px" }}></div>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit}>
              <Form.Group
                style={{
                  textAlign: "center",
                }}
              >
                <PersonCircle color="royalblue" size={66} />
              </Form.Group>

              <Form.Group
                style={{
                  textAlign: "center",
                  fontSize: "27px",
                  fontWeight: "bold",
                }}
              >
                <Form.Text>Register with Masterprime</Form.Text>
              </Form.Group>

              {status.errorMessage ? (
                <DismissableAlert
                  handleClose={() => setStatus({ errorMessage: "" })}
                  message={
                    status.errorMessage === "Account has not been activated" ? (
                      <Alert.Link href="/auth/activate">
                        {status.errorMessage} Click here to activate
                      </Alert.Link>
                    ) : (
                      status.errorMessage
                    )
                  }
                />
              ) : null}
              <Form.Group>
                <Form.Control
                  name="firstname"
                  type="text"
                  size="lg"
                  placeholder="First Name"
                  ref={firstNameRef}
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Control
                  name="lastname"
                  type="text"
                  size="lg"
                  placeholder="Last Name"
                  ref={lastNameRef}
                  required
                />
              </Form.Group>

              <InputGroup className="mb-3">
                <DropdownButton
                  as={InputGroup.Prepend}
                  variant="outline-secondary"
                  id="input-group-dropdown-1"
                  title="+234"
                >
                  <Dropdown.Item>+234</Dropdown.Item>
                </DropdownButton>
                <Form.Control
                  name="phone"
                  type="text"
                  maxLength={11}
                  inputMode="tel"
                  pattern="\d*"
                  size="lg"
                  placeholder="Phone number"
                  ref={phoneRef}
                  required
                />
              </InputGroup>

              <Form.Group>
                <Form.Control
                  name="email"
                  type="email"
                  size="lg"
                  placeholder="Enter email"
                  ref={emailRef}
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Control
                  name="password"
                  type="password"
                  size="lg"
                  placeholder="Create password"
                  ref={passwordRef}
                  required
                />
                <Form.Text id="passwordHelpBlock" muted>
                  Must be at least 8 characters long.
                </Form.Text>
              </Form.Group>

              <Form.Group>
                <Form.Control
                  name="confirm_password"
                  type="password"
                  size="lg"
                  placeholder="Confirm password"
                  ref={confirmPasswordRef}
                  required
                />
              </Form.Group>

              <Form.Group
                className="ml-1"
                as={Row}
                controlId="formBasicCheckbox"
              >
                <Form.Check
                  name="checkbox"
                  style={{ marginTop: "6px" }}
                  type="checkbox"
                />
                <Form.Text
                  style={{
                    fontSize: "17px",
                    textDecoration: "none",
                  }}
                >
                  I have read the <a href="/legal/terms">terms and conditions</a>
                </Form.Text>
              </Form.Group>
              <Button
                size="md"
                variant="primary"
                type="submit"
                disabled={status.loading && true}
                block
                style={{backgroundColor: "#4267b2"}}
              >
                {status.loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Sign Up"
                )}
              </Button>
              <Col className="accountAlt">
                Already have an account? <Link to="/auth/login">Sign In </Link>
              </Col>
            </Form>
            <Row></Row>
          </Col>
          <Col className="d-none d-md-block d-lg-block">
            <AsideImg />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
