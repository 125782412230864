import React, { useState, useEffect } from "react";
import { Button, Container, Form } from "react-bootstrap";
import axios from "axios";
import DismissableAlert from "../components/DismissableAlert";
import SuccessAlert from "../components/SuccessAlert";
import { Base } from "../components/Base";

function PasswordReset(props) {
  const password_reset_code = React.createRef();
  const new_password = React.createRef();
  const confirm_new_password = React.createRef();
  const [status, setStatus] = useState({
    loading: false,
    errorMessege: "",
    successMessage: "",
  });

  const handleSubmit = async (e) => {
    setStatus({ loading: true });
    e.preventDefault();
    try {
      let data = {
        password_reset_code: password_reset_code.current.value,
        new_password: new_password.current.value,
        confirm_new_password: confirm_new_password.current.value,
      };

      const response = await axios.post(
        `${Base}/user/forgot-password/reset-password`,
        data,
        { Accept: "application/json" }
      );

      if (response.data.status === false) {
        setStatus({ errorMessege: response.data.message });
      } else {
        setStatus({ successMessege: response.data.message });
        await props.history.push("/auth/login");
      }
    } catch (err) {
      setStatus({ errorMessage: err.message });
    }
  };

  return (
    <div>
      <Container style={{ marginTop: "200px" }} className="text-center">
        {status.errorMessage && (
          <DismissableAlert
            handleClose={() => setStatus({ errorMessage: "" })}
            variant={true}
            message={status.errorMessage}
          />
        )}
        {status.successMessage && (
          <SuccessAlert
            handleClose={() => setStatus({ successMessage: "" })}
            variant={true}
            message={status.successMessage}
          />
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Label>Reset password</Form.Label>
          <Form.Group>
            <Form.Control
              ref={password_reset_code}
              type="text"
              placeholder="Enter reset code"
              style={{
                width: "250px",
                margin: "10px auto",
              }}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              ref={new_password}
              type="password"
              placeholder="new password"
              required
              style={{
                width: "250px",
                margin: "10px auto",
              }}
            />
            <Form.Text id="passwordHelpBlock" muted>
              Must be at least 8 characters long.
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Control
              ref={confirm_new_password}
              type="password"
              placeholder="confirm new password"
              required
              style={{
                width: "250px",
                margin: "10px auto",
              }}
            />
          </Form.Group>

          <Button
            style={{
              width: "250px",
              margin: "10px auto",
            }}
            type="submit"
            disabled={status.loading ? true : false}
          >
            {status.loading ? "Loading..." : "Continue"}
          </Button>
        </Form>
      </Container>
    </div>
  );
}

export default PasswordReset;
